* {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  font-family: "Nunito", sans-serif;
}

.img-150 {
  height: 150px;
  width: auto;
}

.contactUsBtn:hover {
  text-decoration: underline;
  cursor: pointer;
  font-weight: 600;
}

.anchor {
  display: block;
  position: relative;
  top: -75px;
  visibility: hidden;
}

/* .firstFold {
  background-image: radial-gradient(
      white,
      rgba(255, 255, 255, 0.2) 2px,
      transparent 40px
    ),
    radial-gradient(white, rgba(255, 255, 255, 0.15) 1px, transparent 30px),
    radial-gradient(white, rgba(255, 255, 255, 0.1) 2px, transparent 40px),
    radial-gradient(
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0.1) 2px,
      transparent 30px
    );
  background-size: 550px 550px, 350px 350px, 250px 250px, 150px 150px;
  background-position: 0 0, 40px 60px, 130px 270px, 70px 100px;
} */

.firstFoldBgClass {
  background-color: "#20bf55";
  background-image: linear-gradient(315deg, #20bf55 0%, #006a67 74%);
}

/* .fade-in-banner-text {
  animation: pulse 4s infinite;
} */

@keyframes pulse {
  0% {
    /* color: green; */
  }

  50% {
    opacity: 0;
  }

  100% {
    /* color: green; */
  }
}

.line-to-animate {
  border-right: 2px solid rgba(255, 255, 255, 0.75);
  white-space: nowrap;
  overflow: hidden;
  transform: translateY(-50%);
}

.animation-typewriter {
  animation: typewriter 4s steps(44) 1s 1 normal both,
    blinkTextCursor 500ms steps(44) infinite normal;
}

@keyframes typewriter {
  from {
    width: 0;
  }

  to {
    width: 32em;
  }
}

/* .bannerFoldImage {
  z-index: 20;
} */

.bounce-effect {
  animation: bounce 4s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

.flash {
  animation: flash 500ms ease infinite alternate;
}

@keyframes flash {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.scrollTopClass {
  position: fixed;
  bottom: 50px;
  right: 30px;
  cursor: pointer;
  transition: opacity 0.2s linear 0s, visibility;
  opacity: 1;
  visibility: visible;
  z-index: 999;
  width: 50px;
  height: 50px;
  line-height: 49px;
  border-radius: 50%;
  background-color: #fff;
  text-align: center;
  font-size: 20px;
  overflow: hidden;
  z-index: 999 !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.scrollToBottomArrow {
  position: absolute;
  bottom: 40px;
  left: 50%;
  z-index: 40;
  color: white;
  font-size: 25px;
  cursor: pointer;
  border: 2px solid white;
  height: 40px;
  width: 40px;
  border-radius: 40px;
  text-align: center;
  opacity: 0.7;
}

.bannerFoldSecondDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.bannerFoldThirdDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  width: 80%;
}

.bannerFoldFourthDiv {
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin-left: 20px;
}

.bannerNormalText {
  color: #edebeb;
  font-size: 20px;
}

.bannerBoldText {
  color: #edebeb;
  font-size: 50px;
  font-weight: 700;
}

.bannerFoldImageDiv {
  height: 100%;
  width: 100%;
  display: block;
  padding-top: 40px;
}

.aboutUsFoldSecDiv {
  text-align: center;
  width: 60%;
  margin-top: 2%;
}

.aboutUsFoldHeadingText {
  color: #18453b;
  font-weight: bold;
  font-size: 55px;
  text-transform: uppercase;
}

.aboutUsFoldImage {
  margin-top: 20px;
  margin-bottom: 20px;
}

.aboutUsFoldMissionText {
  color: #18453b;
  font-weight: bold;
  font-size: 20px;
  text-align: left;
  margin-bottom: 10px;
}

.aboutUsFoldSummaryText1 {
  color: #18453b;
  font-weight: initial;
  font-size: 19px;
  text-align: left;
}

.aboutUsFoldSummaryTextPara1 {
  margin-top: 50px;
}

.mapSecondDiv {
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5%;
}

.addressHeadingText {
  font-size: 55px;
  font-weight: 700;
  margin-left: -10px;
  margin-top: 5%;
}

.addressText {
  font-size: 18px;
  font-weight: 500;
}

.maccontactus {
  font-size: 18px;
  font-weight: 500;
  color: black;
  text-decoration: none;
}

.maccontactus:hover {
  color: #1e997e;
  text-decoration: underline;
}

.mapImgDiv {
  height: 100%;
  width: 100%;
}

.mapIFrame {
  float: right;
  height: 480px;
  width: 90%;
  border: 1px solid rgb(231, 229, 229);
  border-radius: 15px;
}

.iconsDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.footerSecondDiv {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 3%;
}

.footerTextDiv {
  width: 100%;
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: space-between;
}

.footerBrandingTextDiv {
  width: 35%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footerAdressTextDiv {
  width: 60%;
}

.footerCopyrightText {
  color: white;
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  width: 100%;
  border-top: 1px solid grey;
  padding-top: 20px;
  margin-top: 30px;
}

.footerContactUsText {
  color: white;
  font-size: 30px;
  font-weight: 700;
}

.footerAddressText {
  color: white;
  font-size: 17px;
  font-weight: 500;
}

.footerContactUs {
  font-size: 17px;
  font-weight: 500;
  color: white;
  text-decoration: none;
}

.footerContactUs:hover {
  color: #5ce29f;
  text-decoration: underline;
}

.footerMapImgDiv {
  height: 100%;
  width: 100%;
}

.footerMapIFrame {
  float: right;
  height: 380px;
  width: 75%;
  border: 1px solid rgb(231, 229, 229);
  border-radius: 15px;
}

.footerIconsDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

a {
  color: white !important;
}

@media screen and (min-width: 100px) and (max-width: 380px) {
  a {
    color: black !important;
  }

  .bannerFoldThirdDiv {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
  }

  .bannerFoldFourthDiv {
    margin-left: 0px;
    margin-top: 40px;
  }

  .bannerFoldImageDiv {
    /* display: none; */
    width: 100%;
    float: none;
    display: flex;
    justify-content: center;
  }

  .bannerFoldImage {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }

  .bannerNormalText {
    font-size: 13px;
  }

  .bannerBoldText {
    margin-bottom: 5px;
    font-size: 30px;
  }

  .aboutUsFoldSecDiv {
    width: 90%;
  }

  .aboutUsFoldHeadingText {
    font-size: 30px;
    padding-top: 10px;
  }

  .countingHeadText {
    font-size: 20px !important;
  }

  .counterup_style--1 h5.counter {
    font-size: 45px !important;
    line-height: 56px !important;
    margin-bottom: 5px !important;
    margin-left: -11px !important;
  }

  .aboutUsFoldImage {
    margin-top: 0px;
    margin-bottom: 0px;
    display: none;
  }

  .aboutUsFoldSummaryText1 {
    font-size: 10.5px;
  }

  .aboutUsFoldMissionText {
    font-size: 18px;
    margin-bottom: 5px;
  }

  .footerSecondDiv {
    width: 90%;
    margin-top: 3%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footerTextDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .footerBrandingTextDiv {
    width: 35%;
  }

  .footerAdressTextDiv {
    width: 60%;
  }

  .footerCopyrightText {
    font-size: 15px;
    margin-top: 20px;
  }

  .footerContactUsText {
    font-size: 18px;
  }

  .footerAddressText {
    font-size: 13px;
  }

  .footerContactUs {
    font-size: 13px;
    color: white !important;
  }

  .footerMapIFrame {
    height: 180px;
    width: 100%;
    margin-top: 20px;
  }
}

@media screen and (min-width: 381px) and (max-width: 600px) {
  a {
    color: black !important;
  }

  .bannerFoldThirdDiv {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
  }

  .bannerFoldFourthDiv {
    margin-left: 0px;
    margin-top: 40px;
  }

  .bannerFoldImageDiv {
    /* display: none; */
    width: 100%;
    float: none;
    display: flex;
    justify-content: center;
  }

  .bannerFoldImage {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }

  .bannerNormalText {
    font-size: 15px;
  }

  .bannerBoldText {
    margin-bottom: 5px;
    font-size: 30px;
  }

  .aboutUsFoldSecDiv {
    width: 90%;
  }

  .aboutUsFoldHeadingText {
    font-size: 40px;
    padding-top: 10px;
  }

  .countingHeadText {
    font-size: 22px !important;
  }

  .counterup_style--1 h5.counter {
    font-size: 45px !important;
    line-height: 56px !important;
    margin-bottom: 5px !important;
    margin-left: -11px !important;
  }

  .aboutUsFoldImage {
    margin-top: 0px;
    margin-bottom: 0px;
    display: none;
  }

  .aboutUsFoldMissionText {
    font-size: 20px;
    margin-bottom: 5px;
  }

  .aboutUsFoldSummaryText1 {
    font-size: 12px;
  }

  .footerSecondDiv {
    width: 90%;
    margin-top: 3%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footerTextDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .footerBrandingTextDiv {
    width: 35%;
  }

  .footerAdressTextDiv {
    width: 60%;
  }

  .footerCopyrightText {
    font-size: 15px;
    margin-top: 20px;
  }

  .footerContactUsText {
    font-size: 20px;
  }

  .footerAddressText {
    font-size: 13px;
  }

  .footerContactUs {
    font-size: 13px;
    color: white !important;
  }

  .footerMapIFrame {
    height: 220px;
    width: 100%;
    margin-top: 20px;
  }
}

@media screen and (min-width: 601px) and (max-width: 900px) {
  a {
    color: black !important;
  }

  .bannerFoldThirdDiv {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
  }

  .bannerFoldFourthDiv {
    margin-left: 0px;
    margin-top: 40px;
  }

  .bannerFoldImageDiv {
    /* display: none; */
    width: 100%;
    float: right;
  }

  .bannerFoldImage {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }

  .bannerNormalText {
    font-size: 22px;
  }

  .bannerBoldText {
    margin-bottom: 5px;
    font-size: 40px;
  }

  .aboutUsFoldSecDiv {
    width: 80%;
  }

  .aboutUsFoldHeadingText {
    font-size: 40px;
    padding-top: 40px;
  }

  .aboutUsFoldImage {
    margin-top: 5px;
    margin-bottom: 25px;
  }

  .aboutUsFoldMissionText {
    font-size: 30px;
    margin-bottom: 5px;
  }

  .aboutUsFoldHeadingText {
    font-size: 45px;
  }

  .countingHeadText {
    font-size: 43px !important;
  }

  .aboutUsFoldSummaryText1 {
    font-size: 17px;
  }

  .footerSecondDiv {
    width: 90%;
    margin-top: 3%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footerTextDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .footerBrandingTextDiv {
    width: 35%;
  }

  .footerAdressTextDiv {
    width: 60%;
  }

  .footerCopyrightText {
    font-size: 15px;
    margin-top: 40px;
  }

  .footerContactUsText {
    font-size: 25px;
  }

  .footerAddressText {
    font-size: 15px;
  }

  .footerContactUs {
    font-size: 15px;
    color: white !important;
  }

  .footerMapIFrame {
    height: 220px;
    width: 100%;
    margin-top: 20px;
  }

  .img-200 {
    width: 260px !important;
  }
}

@media screen and (min-width: 901px) and (max-width: 1200px) {
  .bannerFoldThirdDiv {
    width: 90%;
  }

  .bannerFoldFourthDiv {
    margin-left: 0px;
  }

  .bannerFoldImageDiv {
    height: 40%;
    width: 40%;
    display: block;
    padding-top: 40px;
  }

  .bannerFoldImage {
    height: 100%;
    width: 100%;
  }

  .bannerNormalText {
    font-size: 16px;
  }

  .bannerBoldText {
    margin-bottom: 5px;
    font-size: 30px;
  }

  .aboutUsFoldSecDiv {
    width: 85%;
  }

  .aboutUsFoldHeadingText {
    font-size: 35px;
  }

  .aboutUsFoldImage {
    margin-top: 0px;
    margin-bottom: 0px;
    display: none;
  }

  .aboutUsFoldMissionText {
    font-size: 20px;
    margin-bottom: 5px;
  }

  .aboutUsFoldHeadingText {
    font-size: 45px;
  }

  .aboutUsFoldSummaryText1 {
    font-size: 15px;
  }

  .mapSecondDiv {
    width: 80%;
    margin-top: 5%;
  }

  .addressHeadingText {
    font-size: 40px;
    font-weight: 700;
    margin-left: -10px;
    margin-top: 5%;
  }

  .addressText {
    font-size: 15px;
  }

  .maccontactus {
    font-size: 17px;
  }

  .mapIFrame {
    height: 350px;
    width: 80%;
  }

  .footerSecondDiv {
    width: 90%;
    margin-top: 3%;
  }

  .footerBrandingTextDiv {
    width: 40%;
  }

  .footerAdressTextDiv {
    width: 45%;
  }

  .footerCopyrightText {
    font-size: 15px;
  }

  .footerContactUsText {
    font-size: 22px;
  }

  .footerAddressText {
    font-size: 12px;
  }

  .footerContactUs {
    font-size: 12px;
  }

  .footerMapIFrame {
    height: 220px;
    width: 75%;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1600px) {
  .bannerFoldThirdDiv {
    width: 90%;
  }

  .bannerFoldFourthDiv {
    margin-left: 0px;
  }

  .bannerFoldImageDiv {
    height: 45%;
    width: 45%;
    display: block;
    padding-top: 40px;
  }

  .bannerFoldImage {
    height: 100%;
    width: 100%;
  }

  .bannerNormalText {
    font-size: 17px;
  }

  .bannerBoldText {
    margin-bottom: 5px;
    font-size: 35px;
  }

  .aboutUsFoldSecDiv {
    width: 80%;
  }

  .aboutUsFoldImage {
    margin-top: 0px;
    margin-bottom: 0px;
    display: none;
  }

  .aboutUsFoldHeadingText {
    font-size: 45px;
  }

  .aboutUsFoldSummaryText1 {
    font-size: 16px;
  }

  .mapSecondDiv {
    width: 80%;
    margin-top: 5%;
  }

  .addressHeadingText {
    font-size: 45px;
    font-weight: 700;
    margin-left: -10px;
    margin-top: 5%;
  }

  .addressText {
    font-size: 16px;
  }

  .maccontactus {
    font-size: 17px;
  }

  .mapIFrame {
    height: 420px;
    width: 80%;
  }

  .footerSecondDiv {
    width: 90%;
    margin-top: 3%;
  }

  .footerBrandingTextDiv {
    width: 35%;
  }

  .footerAdressTextDiv {
    width: 45%;
  }

  .footerCopyrightText {
    font-size: 15px;
    margin-top: 30px;
  }

  .footerContactUsText {
    font-size: 25px;
  }

  .footerAddressText {
    font-size: 14px;
  }

  .footerContactUs {
    font-size: 14px;
  }

  .footerMapIFrame {
    height: 260px;
    width: 75%;
  }
}

/* product list */
.medicineSection {
  color: black;
  background-color: #f5f5f5;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
}

.medicineDiv {
  text-align: center;
  width: 60%;
  margin-top: 5%;
}

.medicineFoldHeadingText {
  color: #18453b;
  font-weight: bold;
  font-size: 50px;
  text-transform: uppercase;
  font-size: 35px;
}

.medicineLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin-left: 20px;
}

.card {
  border: none;
  border-radius: 1rem;
  transition: all 0.2s;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
}

.img-200 {
  height: auto;
  width: 100%;
  transition: 1s ease;
}

.img-200:hover {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  transition: 1s ease;
}

.medcineName ul {
  padding-left: 1rem !important;
}

.medcineName ul li {
  margin-bottom: 0.5rem;
}

.fa-ul li {
  text-align: left;
  font-size: 14px;
  list-style: none;
  font-weight: 600;
}

.fa-li .bi {
  color: #18453b;
}

.content-para:hover {
  color: #18453b;
}

/* Hover Effects on Card */

@media (min-width: 992px) {
  .medicineSection .card:hover {
    margin-top: -0.25rem;
    margin-bottom: 0.25rem;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
    border: 1px solid #18453b !important;
  }
}

/* counter */

.counterSection {
  background-color: #ffff;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
}

.counterDiv {
  margin-bottom: 5%;
}

.counterup_style--1 {
  text-align: center;
  margin-top: 40px;
}

.counterup_style--1 h5.counter {
  font-size: 72px;
  line-height: 80px;
  margin-bottom: 17px;
  position: relative;
  display: inline-block;
  color: #000;
  margin-left: -20px;
  font-weight: bold;
}

.counterup_style--1 h5.counter:after {
  right: -26px;
  position: absolute;
  top: -17px;
  font-size: 40px;
  content: "+";
}

.counterup_style--1 p.description {
  font-size: 18px;
  line-height: 30px;
  padding: 0 5%;
  color: rgba(29, 29, 36, 0.75);
}

.container-fluid {
  margin-bottom: 5%;
}

.mobileHeader ul {
  padding: 0;
  list-style-type: none;
}

.mobileHeader ul li a {
  padding-left: 30px;
  display: block;
  margin-bottom: 25px;
  color: #727479;
  text-decoration: none;
  font-size: 16px;
}

.mobIcon {
  display: inline-block;
  color: #fff !important;
  font-size: 25px !important;
}

@media screen and (max-width: 600px) {
  .fa-ul li {
    font-size: 12px !important;
  }
}
